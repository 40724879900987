var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { staticClass: "px-8 py-3", attrs: { color: "inqliNavy" } },
    [
      _c("span", { staticClass: "inqliGray--text" }, [
        _c("div", [
          _vm._v(
            "© " +
              _vm._s(_vm.currentYear) +
              " inqli™. inqli is a trademark of inqli inc."
          )
        ])
      ]),
      _c("div", { staticClass: "footer-link" }, [
        _c("a", { attrs: { href: "mailto:hello@inqli.com" } }, [
          _vm._v("Contact Us")
        ]),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.linkedin.com/company/inqli/",
              target: "_blank"
            }
          },
          [_vm._v("LinkedIn")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }