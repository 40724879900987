<template>
  <div>
    <v-combobox
      v-if="select"
      :search-input.sync="currentValue"
      :label="label"
      append-icon="mdi-plus"
      v-model="modelValue"
      @keydown.enter="addValue"
      @click:append="addValue"
      @change="addValue"
      :items="suggestItemList"
      :placeholder="placeholder"
      ref="combobox"
    />
    <v-text-field
      v-else
      v-model="currentValue"
      :label="label"
      @keydown.enter="addValue"
      append-icon="mdi-plus"
      @click:append="addValue"
      :placeholder="placeholder"
    ></v-text-field>
    <preview-tag-list v-model="value" class="tag-list" />
  </div>
</template>
<script>
import PreviewTagList from "../tags/PreviewTagList.vue";
/**
 * Select multiple value from the suggesting list, it'll show the chosen list below the input
 */
export default {
  name: "MultiValueTextField",
  components: {
    "preview-tag-list": PreviewTagList,
  },
  data() {
    return {
      currentValue: "",
      modelValue: "",
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Array,
    },
    select: {
      type: Boolean,
      default: false,
    },
    addable: {
      type: Boolean,
      default: false,
    },
    selectItemList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    suggestItemList() {
      return this.selectItemList.filter(
        (item) => !this.valueList.includes(item.toLowerCase())
      );
    },
    valueList: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    addValue() {
      const addingValue = this.currentValue || this.modelValue;
      this.$refs.combobox.blur();
      this.currentValue = "";
      this.modelValue = "";
      this.$refs.combobox.lazySearch = "";
      if (!addingValue) return;
      if (this.select && !this.addable) {
        const filterItem = this.selectItemList.filter(
          (item) => item.toLowerCase() === addingValue.toLowerCase()
        )[0];
        if (!filterItem) {
          return;
        }
      }
      const similarItem = this.valueList.filter(
        (item) => item.toLowerCase() === addingValue.toLowerCase()
      )[0];
      if (!similarItem) {
        this.valueList.push(addingValue);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-list {
  margin-top: -10px;
}
</style>
