var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-container" },
    [
      _vm.showTopBanner && !_vm.isLogin ? _c("notification-banner") : _vm._e(),
      _vm._t("header"),
      _c(
        "div",
        {
          class: {
            content: true,
            "ml-14": _vm.showSidebar && _vm.isLogin && !_vm.isMobile
          }
        },
        [_vm._t("default")],
        2
      ),
      _vm.showSidebar && _vm.isLogin ? _c("sidebar") : _vm._e(),
      _c("inqli-footer", { staticClass: "footer" }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbar.timeout },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-icon",
                    _vm._b(
                      {
                        attrs: { color: "white", small: "" },
                        on: {
                          click: function($event) {
                            _vm.snackbar = false
                          }
                        }
                      },
                      "v-icon",
                      attrs,
                      false
                    ),
                    [_vm._v(" mdi-close ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.snackbar.show,
            callback: function($$v) {
              _vm.$set(_vm.snackbar, "show", $$v)
            },
            expression: "snackbar.show"
          }
        },
        [
          _c(
            "span",
            {
              class: {
                "snackbar-text": true,
                "snackbar-text-success": _vm.snackbar.type === "success"
              }
            },
            [_vm._v(" " + _vm._s(_vm.snackbar.message) + " ")]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }