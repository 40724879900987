var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.select
        ? _c("v-combobox", {
            ref: "combobox",
            attrs: {
              "search-input": _vm.currentValue,
              label: _vm.label,
              "append-icon": "mdi-plus",
              items: _vm.suggestItemList,
              placeholder: _vm.placeholder
            },
            on: {
              "update:searchInput": function($event) {
                _vm.currentValue = $event
              },
              "update:search-input": function($event) {
                _vm.currentValue = $event
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.addValue.apply(null, arguments)
              },
              "click:append": _vm.addValue,
              change: _vm.addValue
            },
            model: {
              value: _vm.modelValue,
              callback: function($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue"
            }
          })
        : _c("v-text-field", {
            attrs: {
              label: _vm.label,
              "append-icon": "mdi-plus",
              placeholder: _vm.placeholder
            },
            on: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.addValue.apply(null, arguments)
              },
              "click:append": _vm.addValue
            },
            model: {
              value: _vm.currentValue,
              callback: function($$v) {
                _vm.currentValue = $$v
              },
              expression: "currentValue"
            }
          }),
      _c("preview-tag-list", {
        staticClass: "tag-list",
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }