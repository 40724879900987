var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.tags, function(tag, index) {
      return _c(
        "v-chip",
        {
          key: tag,
          staticClass: "mx-1",
          attrs: { close: "", color: "inqliPurple", outlined: "" },
          on: {
            "click:close": function($event) {
              return _vm.deleteTag(index)
            }
          }
        },
        [_vm._v(" " + _vm._s(tag) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }