var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "650px", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "question-form-container" },
        [
          _c(
            "v-icon",
            {
              staticClass: "close-button",
              attrs: { dense: "", color: "inqliGray" },
              on: { click: _vm.closeDialog }
            },
            [_vm._v("mdi-close")]
          ),
          _c("question-form", { on: { "submit:success": _vm.onSubmitSuccess } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }