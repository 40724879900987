<template>
  <div class="layout-container">
    <notification-banner v-if="showTopBanner && !isLogin" />
    <slot name="header"></slot>
    <div
      :class="{
        content: true,
        'ml-14': showSidebar && isLogin && !isMobile,
      }"
    >
      <slot></slot>
    </div>
    <sidebar v-if="showSidebar && isLogin" />
    <inqli-footer class="footer"></inqli-footer>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      <span
        :class="{
          'snackbar-text': true,
          'snackbar-text-success': snackbar.type === 'success',
        }"
      >
        {{ snackbar.message }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-icon color="white" v-bind="attrs" @click="snackbar = false" small>
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import NotificationBanner from "../banners/NotificationBanner.vue";
import Footer from "../Footer.vue";
import Sidebar from "../Sidebar.vue";
export default {
  name: "DefaultLayout",
  components: {
    "inqli-footer": Footer,
    "notification-banner": NotificationBanner,
    sidebar: Sidebar,
  },
  props: {
    showTopBanner: {
      type: Boolean,
      default: true,
    },
    showSidebar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.state.alert;
      },
      set(value) {
        this.$store.commit("setAlertDisplay", value);
      },
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
    isLogin() {
      return this.$store.state.user.isLogin;
    },
  },
};
</script>
<style lang="scss" scoped>
.layout-container {
  min-height: 100vh;
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
.content {
  margin-bottom: 60px;
}
.snackbar-text-success {
  color: $inqliGreen;
}
@media (max-width: $breakpoint-tablet) {
  .content {
    margin-bottom: 120px;
  }
}
</style>
