var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-banner",
    {
      staticClass: "banner-content",
      attrs: { color: "inqliPurple", dark: "" }
    },
    [
      _vm._v("inqli's iOS beta has begun. "),
      _c("br", { staticClass: "mobile-break" }),
      _vm._v(" Learn more and "),
      _c(
        "a",
        {
          attrs: {
            href: "https://wb1djx03u3q.typeform.com/to/T64HUHsu",
            target: "_blank"
          }
        },
        [_vm._v("join the community")]
      ),
      _vm._v(" today. 🚀 ")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }