<template>
  <v-dialog v-model="dialog" max-width="650px" persistent>
    <v-card class="question-form-container">
      <v-icon dense color="inqliGray" class="close-button" @click="closeDialog"
        >mdi-close</v-icon
      >
      <question-form @submit:success="onSubmitSuccess" />
    </v-card>
  </v-dialog>
</template>
<script>
import QuestionForm from "./QuestionForm.vue";
export default {
  name: "QuestionFormDialog",
  components: {
    "question-form": QuestionForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    onSubmitSuccess(question) {
      this.closeDialog();
      this.$router.push(`/questions/${question.id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.question-form-container {
  padding: 25px;
  border-radius: 16px;
}
.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
