<template>
  <div>
    <v-chip
      class="mx-1"
      v-for="(tag, index) in tags"
      :key="tag"
      close
      color="inqliPurple"
      outlined
      @click:close="deleteTag(index)"
    >
      {{ tag }}
    </v-chip>
  </div>
</template>
<script>
export default {
  name: "TagList",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tags: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    deleteTag(tagIndex) {
      this.tags.splice(tagIndex, 1);
    },
  },
};
</script>
