<template>
  <v-banner color="inqliPurple" dark class="banner-content"
    >inqli's iOS beta has begun.
    <br class="mobile-break" />
    Learn more and
    <a href="https://wb1djx03u3q.typeform.com/to/T64HUHsu" target="_blank"
      >join the community</a
    >
    today. 🚀
  </v-banner>
</template>
<script>
export default {
  name: "NotificationBanner",
};
</script>
<style lang="scss" scoped>
.banner-content {
  font-size: 12px;
  padding: 0 5px;
  overflow: auto;
  a {
    color: $inqliYellow;
    text-decoration: underline !important;
  }
  .v-banner__wrapper {
    padding: 0;
  }
}
.mobile-break {
  display: none;
}
@media (max-width: $breakpoint-tablet) {
  .banner-content {
    height: $banner-tablet-height;
  }
}
@media (max-width: $breakpoint-mobile) {
  .banner-content {
    height: $banner-mobile-height;
  }
  .mobile-break {
    display: block;
  }
}
</style>
