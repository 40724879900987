<template>
  <v-app-bar class="default-header" flat clipped-left :color="color">
    <div class="left-logo">
      <v-icon v-if="showMenuButton" @click="toggleSidebar" class="menuButton">
        mdi-menu</v-icon
      >
      <router-link to="/" class="logo" v-if="showLogo">
        <v-img src="@/app/assets/inqli-logo-dark.svg" />
      </router-link>
    </div>
    <div
      :class="{
        'ml-10': !isMobile && !showLogo,
        'left-button': true,
      }"
    >
      <slot name="left"></slot>
    </div>
    <v-spacer></v-spacer><slot></slot> <v-spacer></v-spacer>

    <div class="right">
      <slot name="right"></slot>
      <inqli-button v-if="isLogin" @click="showDialog = true"
        ><inqli-icon class="mr-2">mdi-help</inqli-icon> Ask a
        Question</inqli-button
      >
      <!-- <inqli-button
        href="/login"
        v-if="showSignInButton"
        class="sign-in-button"
        >Log in</inqli-button
      >
      <v-menu
        offset-y
        v-else-if="showUnAuthMenuButton"
        max-width="576"
        min-width="300"
        left
        slide-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"> mdi-menu</v-icon>
        </template>
        <v-list class="menuList">
          <v-list-item
            v-for="item in menuList"
            :key="item.text"
            router
            :to="item.route"
            active-class="inqliPurple--text"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text" class="font-weight-black">
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
    </div>
    <inqli-question-form-dialog v-model="showDialog" />
  </v-app-bar>
</template>
<script>
import Button from "../buttons/Button.vue";
import QuestionFormDialog from "../../../questions/components/QuestionFormDialog.vue";
import InqliIcon from "../icons/InqliIcon.vue";
import Vue from "vue";
export const EventBus = new Vue();

export default {
  name: "DefaultHeader",
  props: {
    showRightButtons: {
      type: Boolean,
      default: true,
    },
    leftLogoPersist: {
      type: Boolean,
      default: false,
    },
    showMobileLogo: {
      type: Boolean,
      default: true,
    },
    showMobileMenuButton: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "inherit",
    },
  },
  components: {
    "inqli-button": Button,
    "inqli-question-form-dialog": QuestionFormDialog,
    "inqli-icon": InqliIcon,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    EventBus.$on("open-ask-question", this.openDialog);
  },
  computed: {
    showSignInButton() {
      return (
        this.showRightButtons &&
        !this.$store.state.user.isLogin &&
        !this.isMobile
      );
    },
    isLogin() {
      return this.$store.state.user.isLogin;
    },
    showMenuButton() {
      return (
        this.showMobileMenuButton &&
        this.$store.state.user.isLogin &&
        this.isMobile
      );
    },
    showUnAuthMenuButton() {
      return (
        this.showRightButtons &&
        !this.$store.state.user.isLogin &&
        this.isMobile
      );
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
    showLogo() {
      return (
        this.leftLogoPersist ||
        (this.isMobile && this.showMobileLogo) ||
        !this.$store.state.user.isLogin
      );
    },
    menuList() {
      return [
        {
          text: "Log in",
          route: "/login",
          icon: "mdi-login",
        },
      ];
    },
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebarDisplay");
    },
    openDialog() {
      this.showDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.default-header {
  border-bottom: 2px solid #ebeff2;
  padding: 15px 10px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  position: sticky !important;
  height: 60px;
  top: 0;
  z-index: 5;
}
.middle-toolbar {
  background-color: transparent !important;
  display: block;
}
.left-logo {
  position: absolute;
  left: 10px;
  display: flex;
  justify-content: center;
  width: 100px;
}
.left-button {
  z-index: 3;
}
.menuButton {
  margin-right: 15px;
}
.logo {
  width: 60px;
  display: block;
}
.right {
  position: absolute;
  right: 32px;
}
.right {
  position: absolute;
  right: 32px;
}
.right {
  position: absolute;
  right: 32px;
}
@media screen and (max-width: $breakpoint-mobile) {
  .logo {
    width: 38px;
    margin-top: 4px;
  }
  .left-logo {
    left: -10px;
  }
  .right {
    right: 10px;
  }
  .menuButton {
    margin-right: 8px;
  }
  .right {
    right: 10px;
  }
  .right {
    right: 10px;
  }
}
</style>
