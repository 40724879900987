<template>
  <v-footer class="px-8 py-3" color="inqliNavy">
    <span class="inqliGray--text">
      <div>© {{ currentYear }} inqli™. inqli is a trademark of inqli inc.</div>
    </span>
    <div class="footer-link">
      <a href="mailto:hello@inqli.com">Contact Us</a>
      <a href="https://www.linkedin.com/company/inqli/" target="_blank"
        >LinkedIn</a
      >
    </div>
  </v-footer>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  background-color: $inqliIconGray !important;
  a {
    color: $inqliGray;
    margin: 0 20px;
  }

  a:hover {
    text-decoration: none;
  }

  hr {
    margin-top: 0;
  }
}
@media screen and (max-width: $breakpoint-tablet) {
  footer {
    flex-direction: column-reverse;
    .footer-link {
      display: flex;
      justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      a {
        margin: 5px 0;
        display: block;
      }
    }
  }
}
</style>
