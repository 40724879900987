<template>
  <v-navigation-drawer
    v-model="drawer"
    :permanent="!isMobile"
    :mini-variant.sync="mini"
    mini-variant-width="50"
    app
    width="256"
    fixed
    :temporary="isMobile"
  >
    <router-link to="/">
      <v-list-item class="mt-2">
        <v-img
          src="@/app/assets/inqli-logo-dark.svg"
          max-width="65"
          min-width="30"
          class="ma-auto"
          contain
          width="100%"
        />
      </v-list-item>
    </router-link>
    <v-divider class="mt-2 mb-0"></v-divider>
    <v-list class="menuList">
      <v-list-item class="mt-2" dense>
        <v-list-item-action class="mr-3"
          ><avatar size="sidebar" :image="user.avatar"
        /></v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            class="font-weight-black text-left inqliNavy--text userNameSidebar"
          >
            {{ user.display_name }}
          </v-list-item-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="!isMobile"
                icon
                @click.stop="mini = !mini"
                v-bind="attrs"
                v-on="on"
                class="compress-button"
              >
                <v-icon small>mdi-dock-left</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
      <div v-for="(menu, index) in menuList" :key="menu.name">
        <div v-if="menu.isDisplayed">
          <v-divider v-if="index > 0"></v-divider>
          <p v-show="!mini && menu.showName" class="section-title">
            {{ menu.name }}
          </p>
          <div v-for="item in menu.list" :key="item.text" class="menu-item">
            <v-list-item
              router
              :to="item.route"
              nav
              active-class="inqliPurple--text"
              v-if="item.isDisplayed"
            >
              <v-list-item-icon class="justify-center">
                <v-tooltip right :disabled="!mini">
                  <span> {{ item.text }}</span>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="material-symbols-rounded menu-icon"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.icon }}</span
                    >
                  </template>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.text"
                  class="font-weight-black text-left menu-text"
                >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-list>
    <v-divider></v-divider>
    <inqli-button v-show="!mini" width="200" link="/logout" class="mt-3"
      >Log out</inqli-button
    >
  </v-navigation-drawer>
</template>

<script>
import Button from "./buttons/OutlineButton.vue";
import Avatar from "./avatars/Avatar.vue";

export default {
  name: "Sidebar",
  components: {
    avatar: Avatar,
    "inqli-button": Button,
  },
  data() {
    return {
      miniVar: true,
    };
  },
  computed: {
    isSystemAdmin() {
      return this.$store.state.user.isSystemAdmin;
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
    userOrg() {
      return this.$store.state.user.organizations.length > 0
        ? this.$store.state.user.organizations[0]
        : {};
    },
    user() {
      return this.$store.state.user.info && this.$store.state.user.isLogin
        ? this.$store.state.user.info
        : {};
    },
    mini: {
      get() {
        return this.miniVar && !this.isMobile;
      },
      set(value) {
        this.miniVar = value;
      },
    },
    hasOrg() {
      const orgs = this.$store.state.user.organizations;
      return Array.isArray(orgs) && orgs.length > 0;
    },
    hasUserProfile() {
      const user = this.$store.state.user.info;
      return user && user.id;
    },
    menuList() {
      return [
        {
          name: "User",
          showName: false,
          isDisplayed: this.hasUserProfile,
          list: this.userMenuList,
        },
        {
          name: "My Org",
          showName: this.hasOrg,
          isDisplayed: this.hasOrg,
          list: this.orgMenuList,
        },
        {
          name: "Admin",
          showName: this.isSystemAdmin,
          isDisplayed: this.isSystemAdmin,
          list: this.adminMenuList,
        },
      ];
    },
    adminMenuList() {
      return [
        {
          icon: "supervised_user_circle",
          text: "Users",
          route: `/system-admin/users`,
          isDisplayed: true,
        },
        {
          icon: "domain",
          text: "Organizations",
          route: `/system-admin/organizations`,
          isDisplayed: true,
        },
        {
          icon: "library_books",
          text: "Contents",
          route: `/system-admin/contents`,
          isDisplayed: true,
        },
      ];
    },
    userMenuList() {
      return [
        {
          icon: "account_circle",
          text: "My Profile",
          route: `/users/${this.user.id}`,
          isDisplayed: this.hasUserProfile,
        },
        {
          icon: "info",
          text: "My Questions",
          route: `/my-questions`,
          isDisplayed: this.hasUserProfile,
        },
      ];
    },
    orgMenuList() {
      return [
        {
          icon: "domain",
          text: "Profile",
          route: `/organizations/${this.userOrg.id}`,
          isDisplayed: this.hasOrg,
        },
        {
          icon: "people",
          text: "Members",
          route: `/organizations/members/${this.userOrg.id}`,
          isDisplayed: this.hasOrg,
        },
      ];
    },
    drawer: {
      get() {
        return this.$store.state.sideBar.display;
      },
      set(value) {
        return this.$store.commit("setSidebarDisplay", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  position: fixed;
  top: 0;
}
.menuList {
  margin-top: -10px;
}
.menu-icon {
  font-size: 16px !important;
  color: $inqliTableGray;
}
.v-list-item--active {
  .menu-icon {
    color: $inqliPurple;
  }
}
.menu-text {
  font-size: 13px !important;
  font-weight: 700 !important;
}
.menu-item {
  hr {
    margin-bottom: 0 !important;
  }
}
.section-title {
  font-size: 12px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: left;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}
.compress-button {
  position: absolute;
  right: 8px;
}
.userNameSidebar {
  font-size: 14px;
  font-weight: 500;
}
</style>
