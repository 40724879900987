var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        permanent: !_vm.isMobile,
        "mini-variant": _vm.mini,
        "mini-variant-width": "50",
        app: "",
        width: "256",
        fixed: "",
        temporary: _vm.isMobile
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": function($event) {
          _vm.mini = $event
        }
      },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "router-link",
        { attrs: { to: "/" } },
        [
          _c(
            "v-list-item",
            { staticClass: "mt-2" },
            [
              _c("v-img", {
                staticClass: "ma-auto",
                attrs: {
                  src: require("@/app/assets/inqli-logo-dark.svg"),
                  "max-width": "65",
                  "min-width": "30",
                  contain: "",
                  width: "100%"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-2 mb-0" }),
      _c(
        "v-list",
        { staticClass: "menuList" },
        [
          _c(
            "v-list-item",
            { staticClass: "mt-2", attrs: { dense: "" } },
            [
              _c(
                "v-list-item-action",
                { staticClass: "mr-3" },
                [
                  _c("avatar", {
                    attrs: { size: "sidebar", image: _vm.user.avatar }
                  })
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass:
                        "font-weight-black text-left inqliNavy--text userNameSidebar"
                    },
                    [_vm._v(" " + _vm._s(_vm.user.display_name) + " ")]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.isMobile,
                                          expression: "!isMobile"
                                        }
                                      ],
                                      staticClass: "compress-button",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          _vm.mini = !_vm.mini
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("mdi-dock-left")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [_c("span", [_vm._v("Close")])]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.menuList, function(menu, index) {
            return _c("div", { key: menu.name }, [
              menu.isDisplayed
                ? _c(
                    "div",
                    [
                      index > 0 ? _c("v-divider") : _vm._e(),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.mini && menu.showName,
                              expression: "!mini && menu.showName"
                            }
                          ],
                          staticClass: "section-title"
                        },
                        [_vm._v(" " + _vm._s(menu.name) + " ")]
                      ),
                      _vm._l(menu.list, function(item) {
                        return _c(
                          "div",
                          { key: item.text, staticClass: "menu-item" },
                          [
                            item.isDisplayed
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      router: "",
                                      to: item.route,
                                      nav: "",
                                      "active-class": "inqliPurple--text"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      { staticClass: "justify-center" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              right: "",
                                              disabled: !_vm.mini
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "material-symbols-rounded menu-icon"
                                                            },
                                                            "span",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(item.icon)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(" " + _vm._s(item.text))
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass:
                                            "font-weight-black text-left menu-text",
                                          domProps: {
                                            textContent: _vm._s(item.text)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          })
        ],
        2
      ),
      _c("v-divider"),
      _c(
        "inqli-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.mini,
              expression: "!mini"
            }
          ],
          staticClass: "mt-3",
          attrs: { width: "200", link: "/logout" }
        },
        [_vm._v("Log out")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }