<template>
  <div>
    <h2 class="mb-2">My Question</h2>
    <div>
      <p class="form-item-label">Title</p>
      <v-text-field
        aria-label="title"
        placeholder="Add a descriptive title"
        class="form-item"
        v-model="question.title"
      />
    </div>
    <div>
      <p class="form-item-label">Question</p>
      <inqli-text-area v-model="question.details" aria-label="details">
        <span>
          Wonder out loud...
          <br />
          <br />
          Great questioning is a skill we can all improve. In order to be
          matched to the most useful replies, provide as much detail as you can
          and don't be afraid to ask multiple, related questions.
        </span>
      </inqli-text-area>
      <div class="question-help">
        <a
          target="_blank"
          class="question-help-link"
          href="https://inqli.notion.site/Question-Builder-Help-54fa3b97a64948d2a61f7c7d58a5a33a"
          ><inqli-icon class="mr-1 question-help-icon">mdi-help</inqli-icon>
          Click here</a
        >
        to learn more about asking great questions
      </div>
    </div>
    <div class="mt-4">
      <p class="form-item-label">What kind of support would be most helpful?</p>
      <div>
        <toggle-button-group
          v-model="questionType"
          :options="questionTypeOptions"
          rounded
        />
      </div>
    </div>
    <div class="mt-4">
      <p class="form-item-label">Tags</p>
      <multi-value-text-field
        class="form-item"
        placeholder="Add tags for better matches"
        v-model="tags"
        :addable="true"
        :select="true"
        :selectItemList="suggestedTags"
      ></multi-value-text-field>
    </div>
    <v-tooltip top :disabled="enableSubmit">
      <span>Please fill in all fields</span>
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <inqli-button
            width="160px"
            class="mt-7"
            :disabled="!enableSubmit"
            @click="submit"
          >
            Submit</inqli-button
          >
        </span>
      </template>
    </v-tooltip>
  </div>
</template>
<script>
import Button from "../../app/components/buttons/Button.vue";
import InqliIcon from "../../app/components/icons/InqliIcon.vue";
import MultiValueTextField from "../../app/components/inputs/MultiValueTextField.vue";
import ToggleButtonGroup from "../../app/components/buttons/ToggleButtonGroup.vue";
import InqliTextArea from "../../app/components/inputs/TextArea.vue";
import {
  getQuestionTagList,
  createQuestion,
} from "../utils/question-request.js";

export default {
  data() {
    return {
      question: {
        title: "",
        details: "",
        type: "",
      },
      tags: [],
      questionType: "",
      suggestedTags: [],
    };
  },
  components: {
    "inqli-button": Button,
    "inqli-icon": InqliIcon,
    "multi-value-text-field": MultiValueTextField,
    "toggle-button-group": ToggleButtonGroup,
    "inqli-text-area": InqliTextArea,
  },
  async mounted() {
    this.suggestedTags = await getQuestionTagList();
  },

  computed: {
    enableSubmit() {
      const question = this.question;
      return (
        question.title.length > 0 &&
        question.details.length > 0 &&
        question.type.length > 0
      );
    },
    questionTypeOptions() {
      return [
        {
          label: "Instruction",
          value: "instruction",
        },
        {
          label: "Advice",
          value: "advice",
        },
        {
          label: "Coaching",
          value: "coaching",
        },
        {
          label: "All",
          value: "iac-all",
        },
      ];
    },
  },
  watch: {
    questionType(newValue, oldValue) {
      if (newValue) {
        this.question.type = newValue;
      } else {
        this.question.type = oldValue;
        this.questionType = oldValue;
      }
    },
  },
  methods: {
    async submit() {
      try {
        const question = this.question;
        const tags = this.tags;
        const response = await createQuestion(question, tags);
        this.resetQuestionForm();
        this.$emit("submit:success", response);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while creating question"
        );
      }
    },
    resetQuestionForm() {
      this.question = {
        title: "",
        details: "",
        type: "",
      };
      this.questionType = "";
      this.tags = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/_extends.scss";

.form-item-label {
  @extend %input-label;
}
.form-item {
  margin-top: -20px;
}
.question-body-input {
  border-radius: 8px;
  resize: vertical;
  background-color: transparent;
  z-index: 1;
  margin-bottom: 2px;
}
.question-body-input-container {
  position: relative;
  .question-body-placeholder {
    font-size: 13px;
    color: $inqliDarkGray;
    line-height: 1.8;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 2px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;
  }
}
.question-help {
  text-align: left;
  position: relative;
  top: -20px;
  margin-bottom: -20px;
  z-index: 3;
}
.v-tex-field_details {
  display: none;
}
.question-help-link {
  color: $inqliPurple;
}
</style>
