var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "mb-2" }, [_vm._v("My Question")]),
      _c(
        "div",
        [
          _c("p", { staticClass: "form-item-label" }, [_vm._v("Title")]),
          _c("v-text-field", {
            staticClass: "form-item",
            attrs: {
              "aria-label": "title",
              placeholder: "Add a descriptive title"
            },
            model: {
              value: _vm.question.title,
              callback: function($$v) {
                _vm.$set(_vm.question, "title", $$v)
              },
              expression: "question.title"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("p", { staticClass: "form-item-label" }, [_vm._v("Question")]),
          _c(
            "inqli-text-area",
            {
              attrs: { "aria-label": "details" },
              model: {
                value: _vm.question.details,
                callback: function($$v) {
                  _vm.$set(_vm.question, "details", $$v)
                },
                expression: "question.details"
              }
            },
            [
              _c("span", [
                _vm._v(" Wonder out loud... "),
                _c("br"),
                _c("br"),
                _vm._v(
                  " Great questioning is a skill we can all improve. In order to be matched to the most useful replies, provide as much detail as you can and don't be afraid to ask multiple, related questions. "
                )
              ])
            ]
          ),
          _c("div", { staticClass: "question-help" }, [
            _c(
              "a",
              {
                staticClass: "question-help-link",
                attrs: {
                  target: "_blank",
                  href:
                    "https://inqli.notion.site/Question-Builder-Help-54fa3b97a64948d2a61f7c7d58a5a33a"
                }
              },
              [
                _c("inqli-icon", { staticClass: "mr-1 question-help-icon" }, [
                  _vm._v("mdi-help")
                ]),
                _vm._v(" Click here")
              ],
              1
            ),
            _vm._v(" to learn more about asking great questions ")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "mt-4" }, [
        _c("p", { staticClass: "form-item-label" }, [
          _vm._v("What kind of support would be most helpful?")
        ]),
        _c(
          "div",
          [
            _c("toggle-button-group", {
              attrs: { options: _vm.questionTypeOptions, rounded: "" },
              model: {
                value: _vm.questionType,
                callback: function($$v) {
                  _vm.questionType = $$v
                },
                expression: "questionType"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("p", { staticClass: "form-item-label" }, [_vm._v("Tags")]),
          _c("multi-value-text-field", {
            staticClass: "form-item",
            attrs: {
              placeholder: "Add tags for better matches",
              addable: true,
              select: true,
              selectItemList: _vm.suggestedTags
            },
            model: {
              value: _vm.tags,
              callback: function($$v) {
                _vm.tags = $$v
              },
              expression: "tags"
            }
          })
        ],
        1
      ),
      _c(
        "v-tooltip",
        {
          attrs: { top: "", disabled: _vm.enableSubmit },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "span",
                    _vm._g(_vm._b({}, "span", attrs, false), on),
                    [
                      _c(
                        "inqli-button",
                        {
                          staticClass: "mt-7",
                          attrs: {
                            width: "160px",
                            disabled: !_vm.enableSubmit
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Submit")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Please fill in all fields")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }