var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      staticClass: "default-header",
      attrs: { flat: "", "clipped-left": "", color: _vm.color }
    },
    [
      _c(
        "div",
        { staticClass: "left-logo" },
        [
          _vm.showMenuButton
            ? _c(
                "v-icon",
                { staticClass: "menuButton", on: { click: _vm.toggleSidebar } },
                [_vm._v(" mdi-menu")]
              )
            : _vm._e(),
          _vm.showLogo
            ? _c(
                "router-link",
                { staticClass: "logo", attrs: { to: "/" } },
                [
                  _c("v-img", {
                    attrs: { src: require("@/app/assets/inqli-logo-dark.svg") }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          class: {
            "ml-10": !_vm.isMobile && !_vm.showLogo,
            "left-button": true
          }
        },
        [_vm._t("left")],
        2
      ),
      _c("v-spacer"),
      _vm._t("default"),
      _vm._v(" "),
      _c("v-spacer"),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm._t("right"),
          _vm.isLogin
            ? _c(
                "inqli-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDialog = true
                    }
                  }
                },
                [
                  _c("inqli-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-help")
                  ]),
                  _vm._v(" Ask a Question")
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _c("inqli-question-form-dialog", {
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }